window.$ = $
window.jQuery = $
var moment = require('moment')
import 'semantic-ui-css/semantic.min.css'
import 'semantic-ui-css/semantic.min'
import '../node_modules/semantic-ui-calendar/dist/calendar.min.css'
import '../node_modules/semantic-ui-calendar/dist/calendar.min.js'
import '../node_modules/dropzone/dist/dropzone.css'
import '../node_modules/select2/dist/css/select2.min.css'
import '../node_modules/select2/dist/js/select2.full.min.js'
import './autocomplete_light/autocomplete_light.js'
import './select2.css'
import './index.css'
import 'material-colors/dist/colors.css'
import {Calendar} from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import {Dropzone} from 'dropzone'

function isMobileDevice() {
    // Get the width of the screen
    var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    // Define a threshold to determine if the device is a mobile device
    var mobileThreshold = 769 // You can adjust this threshold based on your needs

    // Check if the screen width is less than the threshold
    if (screenWidth < mobileThreshold) {
        return true // It's a mobile device
    } else {
        return false // It's not a mobile device
    }
}

function getDayString(date) {

    let year = date.getFullYear()
    let month = ('0' + (date.getMonth() + 1)).slice(-2) // Months are zero-based
    let day = ('0' + date.getDate()).slice(-2)

// Format the date string
    let dateString = year + '-' + month + '-' + day
    return dateString
}

$(function () {
    $('.ui.dropdown').dropdown()
    $('.ui.accordion').accordion()
    $('.menu .item')
        .tab()

    let isMobile = isMobileDevice()

    console.log('ismobile', isMobile)
    $('.left.side-menu.menu').sidebar('setting', {
        'dimPage': false,
        transition: isMobile ? 'overlay' : 'push',
        duration: isMobile ? 50 : 500,
        onVisible: function () {
            $('.top-menu').addClass('sidebar-opened').removeClass('sidebar-closed')
        },
        onHidden: function () {
            $('.top-menu').addClass('sidebar-closed').removeClass('sidebar-opened')
        },
    })
    if (isMobile) {
        $('.left.side-menu.menu').sidebar('hide')
    }

    $('.left.side-menu.menu').first()
        .sidebar('attach events', '.top-menu .sidebar-control-button')


    $('.tooltip')
        .popup({
            inline: true
        })


    $('.datepicker').calendar({
        type: 'date',
        formatter: {
            date: function (date, settings) {
                return moment(date).format('YYYY-MM-DD')
            }
        }
    })
    if ($('#id_from_date').length > 0 && $('#id_to_date').length > 0) {
        let from_date = $('#id_from_date')
        let from_date_container = from_date.parent().parent('.datepicker')
        let to_date = $('#id_to_date')
        let to_date_container = to_date.parent().parent('.datepicker')

        from_date_container.calendar('setting', 'endCalendar', to_date_container)
        to_date_container.calendar('setting', 'startCalendar', from_date_container)

    }

    $('.date-range').each(function (i, el) {
        $(el).find('input').daterangepicker(
            {
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract('days', 1), moment().subtract('days', 1)],
                    'Last 7 Days': [moment().subtract('days', 6), moment()],
                    'Last 30 Days': [moment().subtract('days', 29), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract('month', 1).startOf('month'), moment().subtract('month', 1).endOf('month')]
                },
                startDate: moment().subtract('days', 29),
                endDate: moment()
            },
            function (start, end) {
                $(el).find('.date-from').val(start.format('MM/DD/YYYY'))
                $(el).find('.date-to').val(end.format('MM/DD/YYYY'))
            }
        )
    })

// $('.datepicker').on('focus', function (e) {
//     $(this).calendar({
//         type: 'date',
//         formatter: {
//             date: function (date, settings) {
//                 return moment(date).format('YYYY-MM-DD')
//             }
//         }
//     })
// });

// $('.ui.sidebar').sidebar('attach events', '.toc.item');

    $('.ui.modal').modal({
        autofocus: false,
        centered: false,
        transition: 'fade up',
        duration: 200,
        onShow: function () {

            $(this).find('.datepicker').calendar({
                type: 'date',
                formatter: {
                    date: function (date, settings) {
                        return moment(date).format('YYYY-MM-DD')
                    }
                }
            })
        },
        onApprove: function (e) {

            var $form = $(this).find('.form')

            if ($form.length >= 1) {

                if ($form.get(0).checkValidity()) {
                    console.log('approve this', $form)
                    $form.submit()
                } else {
                    $form.get(0).reportValidity()
                    return false
                }
            }
        },
        onVisible: function () {
            var $modal = $(this)
            var action_name = $modal.find('#action_name').val()
            var action_url = $modal.find('#action_url').val()
            var action_label = $modal.find('#action_label').val()

            var $box = $modal.find('.content')

            if (action_url) {
                $.get(action_url).done(function (s) {
                    $box.html(s)
                }).fail(function () {
                    $box.html('<div>Error! Please try again</div>')
                })
            }

        }
    })

    $(document).on('click', '.show-modal', function (e) {
        var d = $(this).data(), target = d.target

        $(target).find('#action_name').attr('name', d.actionname)
        $(target).find('#action_name').val(d.actionname)
        $(target).find('#action_url').val(d.actionurl)
        $(target).find('#action_label').val(d.actionlabel)
        $(target).find('h3.header').html(d.actionlabel)

        $(target).modal('show')
    })

// Bind on user field change
    $(':input[name$=user]').on('change', function () {
        // Get the field prefix, ie. if this comes from a formset form
        var prefix = $(this).getFormPrefix()
        var s = this.name.replace('user', '')
        // Clear the autocomplete with the same prefix
        $(':input[name=' + prefix + s + 'account]').val(null).trigger('change')
    })

    $(':input[type=number]').on('mousewheel', function (e) {
        e.preventDefault()
    })

    var account_spans = $('span.account')
    if (account_spans.length > 0) {
        var id = []
        for (var i = 0; i < account_spans.length; i++) {
            var s = $(account_spans[i]).data('id')
            if ($.inArray(s, id) < 0) {
                id.push(s)
            }
        }
        $.post(ACC_PARSER, {q: id}).done(function (s) {
            for (var i = 0; i < s.length; i++) {
                var html = '[' + s[i].type + '] ' + s[i].name
                if ($('.id-' + s[i].id).hasClass('balance')) {
                    html += ' <i>' + s[i].balance + '</i>'
                }
                $('.id-' + s[i].id).html(html)
            }
        })
    }
})

window.addEventListener('load', function () {
    $(function () {
        function hideBankTransfer() {

            $('#cash_out_update_form #id_banking_institution').parent().parent().hide()
            $('#cash_out_update_form #id_banking_account_number').parent().parent().hide()
        }

        function showBankTransfer() {

            $('#cash_out_update_form #id_banking_institution').parent().parent().show()
            $('#cash_out_update_form #id_banking_account_number').parent().parent().show()
        }

        function hideBank_Cheque() {
            $('#cash_out_update_form #id_name_on_cheque').parent().hide()
        }

        function showBank_Cheque() {
            $('#cash_out_update_form #id_name_on_cheque').parent().show()
        }

        function displayField(selectedType) {
            if (selectedType == 'BANK_TRANSFER') {
                hideBank_Cheque()
                showBankTransfer()
            } else if (selectedType == 'BANK_CHEQUE') {
                showBank_Cheque()
                hideBankTransfer()
            } else if (selectedType == 'CASH') {
                hideBank_Cheque()
                hideBankTransfer()
            }
        }

        displayField($('#cash_out_update_form input[name="payment_option"]').attr('value'))

        $('#cash_out_update_form input[name="payment_option"]').on('change', function () {
            displayField($(this).attr('value'))
        })
    })
})

//calendar

$(document).ready(function () {

    let calendarEl = document.getElementById('main-calendar')
    if (calendarEl) {
        let calendar = new Calendar(calendarEl, {
            plugins: [dayGridPlugin],
            initialDate: calendarInitDate,
            customButtons: {
                customPrev: {
                    text: '',
                    icon: 'chevron-left',
                    click: function () {
                        window.location.replace(calendarInitPrev)
                    }
                },
                customNext: {
                    text: '',
                    icon: 'chevron-right',
                    click: function () {
                        window.location.replace(calendarInitNext)
                    }
                }
            },
            initialView: 'dayGridMonth',
            headerToolbar: {
                left: 'customPrev,customNext',
                center: 'title',
                right: ''
            },
            dayMaxEventRows: true,
            firstDay: 1,//Monday
            events: calendarEvent,
            dayCellContent: function (arg) {
                // Modify the rendering of day numbers
                let dayNumber = arg.dayNumberText
                let dateFormatted = getDayString(arg.date)
                let dayLink = calendarDateLink + '?from_date=' + dateFormatted + '&to_date=' + dateFormatted + '&back=' + calendarDateBackLink
                // var content = '<div class="custom-day-number">aaa' + dayNumber + '</div>';
                let content = '<a  href="' + dayLink + '" id="fc-dom-36" class="fc-daygrid-day-number-inner">' + dayNumber + '</a>'
                if (calendarAccountType !== 'GENERAL')
                    content = '<span   id="fc-dom-36" class="fc-daygrid-day-number-inner">' + dayNumber + '</span>'
                return {html: content}
            },
            eventContent: (arg) => {
                event = arg.event
                return {html: `<a class="calendar-item-event-link" href="${event.extendedProps.link}?back=${calendarDateBackLink}" ><span class="calendar-item-event-title"><i class="icon tag" style="color:${event.extendedProps.color}"></i>${event.title}</span><span class="calendar-item-event-amount">${event.extendedProps.amount}</span></a>`}
            }
        })
        calendar.render()
    }
    // select account
    $('.current-account-select-button')
        .popup({
            popup: $('.select-account-popup'),
            position: 'bottom left',
            on: 'click'
        })


    //upload file
    if ($('div.dropzone-init').length > 0) {
        const dropzone = new Dropzone('div.dropzone-init', {
            url: '.',
            autoProcessQueue: false,
            uploadMultiple: false,
            clickable: false,
            drop: function (e) {
                console.log(e)
                let fileInput = document.querySelector('.dropzone-file-hidden')
                fileInput.files = e.dataTransfer.files
            },
            addedfile: function (file) {
                console.log(file.dataTransfer)
                $('.dropzone-file-container input').attr('name', 'attachment_file')
                // $('.dropzone-file-container input').name = 'file'
            },
            // disablePreviews: true,
            hiddenInputContainer: 'div.dropzone-file-container',

        })
        $('div.dropzone-init').on('click', function (e) {
            let fileInput = document.querySelector('.dropzone-file-hidden')
            fileInput.click()
        })
        $('.dropzone-file-hidden').on('change', function (e) {

            let selectedFile = 'Selected file: ' + this.files[0].name
            $('div.dropzone-init .dz-message').text(selectedFile)
        })
    }

    //transfer
    function formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
        try {
            decimalCount = Math.abs(decimalCount)
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount

            const negativeSign = amount < 0 ? '-' : ''

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
            let j = (i.length > 3) ? i.length % 3 : 0

            return negativeSign +
                (j ? i.substring(0, j) + thousands : '') +
                i.substring(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
                (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
        } catch (e) {
            console.log(e)
        }
    }

    if ($('#transfer_check_button').length > 0) {
        $('#transfer_check_button').on('click', function () {

            let transfer_form = $(this).parents('form')

            let selected_from_account_id = transfer_form[0].id_from_account.value
            let selected_to_account_id = transfer_form[0].id_to_account.value
            let selected_amount = transfer_form[0].id_amount.value

            let description = transfer_form[0].id_description.value
            let error_message = ''
            let error_ = ''

            if (!selected_from_account_id || !selected_to_account_id || !selected_amount || !description) {
                $('.main-messages .content').html('Please fill all the field !')
                $('.main-messages').show()
            } else if (selected_amount <= 0) {
                $('.main-messages .content').html('Amount must be greater than 0')
                $('.main-messages').show()
            } else {
                let from_account = null
                let to_account = null
                list_select_account.forEach(function (account_item) {
                    if (account_item.id == selected_from_account_id) {
                        from_account = account_item
                    }
                    if (account_item.id == selected_to_account_id) {
                        to_account = account_item
                    }
                })

                if (from_account.balance * 1 < selected_amount * 1) {
                    $('.main-messages .content').html('Insufficient funds')
                    $('.main-messages').show()
                } else if (selected_to_account_id === selected_from_account_id) {
                    $('.main-messages .content').html('Please select different account to transfer')
                    $('.main-messages').show()

                } else {
                    $('.main-messages').hide()
                    $('.row.step1').hide()
                    $('.row.step2').show()


                    $('.confirm_data_from_account').html(` ${from_account.name} - ${from_account.number}`)
                    $('.confirm_data_to_account').html(` ${to_account.name} - ${to_account.number}`)
                    $('.confirm_data_from_old_balance').html(`${formatMoney(from_account.balance)}`)
                    $('.confirm_data_to_old_balance').html(`${formatMoney(to_account.balance)}`)
                    $('.confirm_data_from_new_balance').html(`${formatMoney(parseFloat(from_account.balance) - parseFloat(selected_amount))}`)
                    $('.confirm_data_to_new_balance').html(`${formatMoney(parseFloat(to_account.balance) + parseFloat(selected_amount))}`)
                    $('.confirm_data_from_amount').html(`- ${formatMoney(selected_amount)}`)
                    $('.confirm_data_to_amount').html(`+ ${formatMoney(selected_amount)}`)
                    $('.confirm_data_description').html(`${description}`)
                }


            }


        })
    }
    $('.message .close')
        .on('click', function () {
            $(this)
                .closest('.message')
                .transition('fade')

        })


    //fix select 2

    window.addEventListener('dal-function-registered.select2', function () {
        $(function () {
            $('select[data-autocomplete-light-function=select2]')
                .filter('select[data-autocomplete-light-url]')
                .each(function (i, obj) {
                    let select = $(this)
                    if (!select.data('autocompleteLightUrl')) {
                        return
                    }

                    let val = select.val()
                    if ($.isEmptyObject(val)) {
                        return
                    }

                    // let forward = yl.getForwards(select)
                    // if (forward === undefined) {
                    //   return
                    // }
                    let forward = {}
                    // forward = JSON.parse(forward)
                    forward['id'] = select.val()
                    forward = JSON.stringify(forward)

                    $.ajax({
                        type: 'GET',
                        url: select.data('autocompleteLightUrl'),
                        data: {
                            forward: forward,
                        },
                    }).then(function (data) {
                        // create the option and append to Select2
                        select.empty()
                        for (let i = 0; i < data.results.length; i++) {
                            let option = new Option(
                                data.results[i].selected_text,
                                data.results[i].id,
                                data.results[i].id == val ? true : false,
                                data.results[i].id == val ? true : false,
                            )
                            select.append(option).trigger('change')
                        }
                        console.log('data', data)
                        console.log('val', val)

                        // manually trigger the `select2:select` event
                        select.trigger({
                            type: 'select2:select',
                            params: {
                                data: data,
                            },
                        })
                    })
                })
        })
    })

})